(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/fraction-payment.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/fraction-payment.js');
"use strict";


const {
  reducerStates
} = svs.components.marketplaceData.constants;
setGlobal('svs.components.marketplaceData.selectors.fractionPayment', {
  selectPurchaseState: state => state.FractionPayment.fractionPurchaseState,
  selectIsLoading: state => state.FractionPayment.fractionPurchaseState === reducerStates.LOADING
});

 })(window);