(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/bet-proposal.js');
"use strict";


const {
  helpers
} = svs.components.marketplace;
function getBetProposalRow(state, _ref) {
  let {
    productName,
    drawNumber,
    groupId
  } = _ref;
  const id = helpers.getRowId(productName, drawNumber, groupId);
  return state.BetProposal[id];
}
function getBetProposal(state, _ref2) {
  let {
    activatedDrawId
  } = _ref2;
  return (state.BetProposal[activatedDrawId] || {}).data;
}
function selectCreateBetProposal(state, _ref3) {
  let {
    activatedDrawId
  } = _ref3;
  return (state.CreateBetProposal[activatedDrawId] || {}).data;
}
setGlobal('svs.components.marketplaceData.selectors.betProposal', {
  getBetProposalRow,
  getBetProposal,
  selectCreateBetProposal
});

 })(window);