(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/chat.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/chat.js');
"use strict";


const selectChatRooms = state => {
  var _state$Chat;
  return (state === null || state === void 0 || (_state$Chat = state.Chat) === null || _state$Chat === void 0 ? void 0 : _state$Chat.rooms) || {};
};
setGlobal('svs.components.marketplaceData.selectors.chat', {
  selectChatRooms
});

 })(window);