(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/form.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/form.js');
"use strict";


const {
  games,
  betProposal
} = svs.components.marketplaceData.selectors;
const hasBetProposalChanged = (currenBetProposal, createBetProposalState) => JSON.stringify(currenBetProposal) !== JSON.stringify(createBetProposalState);
const isDateDiff = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }
  return new Date(date1).getTime() !== new Date(date2).getTime();
};
const hasGameChanged = (currentGame, createGameState) => createGameState.description !== currentGame.description || isDateDiff(createGameState.buyStop, currentGame.buyStop) || createGameState.maxFractionsPerMember !== currentGame.maxFractionsPerMember || createGameState.maxFractionsPerGame !== currentGame.maxFractions;
const selectIsFormChanged = (state, _ref) => {
  let {
    activatedDrawId,
    groupId
  } = _ref;
  const currentGame = games.getGame(state, {
    activatedDrawId,
    groupId
  }) || {};
  const currenBetProposal = betProposal.getBetProposal(state, {
    activatedDrawId
  }) || {};
  const createBetProposalState = betProposal.selectCreateBetProposal(state, {
    activatedDrawId
  }) || {};
  return hasGameChanged(currentGame, state.CreateGame) || hasBetProposalChanged(currenBetProposal, createBetProposalState);
};
setGlobal('svs.components.marketplaceData.selectors.form', {
  selectIsFormChanged
});

 })(window);