(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/results.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/results.js');
"use strict";


const selectBrowseGamesResults = state => {
  const {
    error,
    loading,
    data: results,
    offset,
    totalCount
  } = state.BrowseGamesResults || {};
  const initialLoading = loading && offset === 0;
  const nextLoading = loading && offset > 0;
  return {
    error,
    loading: initialLoading,
    nextLoading,
    results,
    hasMoreData: offset < totalCount,
    offset
  };
};
setGlobal('svs.components.marketplaceData.selectors.results', {
  selectBrowseGamesResults
});

 })(window);