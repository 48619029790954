(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/helpers/get-playable-amount.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/helpers/get-playable-amount.js');
"use strict";


const isValidNumber = number => isNaN(parseInt(number, 10));
const getPlayableAmount = game => {
  const fractionsCount = Number(game.fractionsCount);
  const fractionPrice = Number(game.fractionPrice);
  if (isValidNumber(game.fractionPrice) || isValidNumber(game.fractionsCount)) {
    return null;
  }
  return fractionPrice * fractionsCount;
};
setGlobal('svs.components.marketplaceData.selectors.helpers.getPlayableAmount', getPlayableAmount);

 })(window);