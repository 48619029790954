(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/teams.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/teams.js');
"use strict";

const {
  PAGE_SIZE
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  groupTypeNames
} = svs.components.marketplaceData.constants;
const {
  selectBrowseGamesResults
} = svs.components.marketplaceData.selectors.results;
const getTeams = _ref => {
  let {
    Teams
  } = _ref;
  return Teams.data.filter(team => {
    const isRemoved = Teams.removedTeams.indexOf(Number(team.id)) !== -1;
    const isPrivate = team.type === 'PRIVATE';
    return !isRemoved && isPrivate;
  });
};

const selectTeam = (state, _ref2) => {
  let {
    id
  } = _ref2;
  return state && state.Teams && !state.Teams.removedTeams.find(i => i === Number(id)) && state.Teams.data.find(t => t.id === Number(id)) || {};
};
const getCustomerTeamsResult = state => {
  const {
    loading,
    data,
    customerTeamsOffset,
    customerTeamsCount,
    customerTeamsTotalHits
  } = state.Teams || {};
  return {
    loading,
    data,
    hasMoreData: data.filter(team => team.type === groupTypeNames.PRIVATE).length < customerTeamsTotalHits,
    nextLoading: loading && customerTeamsOffset > 0,
    nextLoadCount: Math.min(customerTeamsCount - customerTeamsOffset, PAGE_SIZE)
  };
};
const selectCustomerTeamsOffset = _ref3 => {
  let {
    Teams
  } = _ref3;
  return Teams.customerTeamsOffset;
};
const getRetailers = (state, selectFromBrowseGames) => {
  if (selectFromBrowseGames) {
    return selectBrowseGamesResults(state).results;
  }
  return state.Teams.data.filter(team => {
    const isFollowing = team.isFollowing;
    const isRemoved = state.Teams.removedTeams.indexOf(Number(team.id)) !== -1;
    const isRetailer = team.type === 'RETAILER';
    return !isRemoved && isRetailer && isFollowing;
  });
};
const getRetailer = (state, groupId) => getRetailers(state).find(i => i === Number(groupId)) && state.Teams.data.find(t => t.id === Number(groupId)) || {};
const getRetailerTeamsResult = state => {
  const {
    loading,
    data,
    retailerTeamsOffset,
    retailerTeamsCount,
    retailerTeamsTotalHits
  } = state.Teams || {};
  return {
    loading,
    data,
    hasMoreData: data.filter(team => team.type === groupTypeNames.RETAILER).length < retailerTeamsTotalHits,
    nextLoading: loading && retailerTeamsOffset > 0,
    nextLoadCount: Math.min(retailerTeamsCount - retailerTeamsOffset, PAGE_SIZE)
  };
};
const selectRetailerTeamsOffset = _ref4 => {
  let {
    Teams
  } = _ref4;
  return Teams.retailerTeamsOffset;
};
const selectIsLoading = _ref5 => {
  let {
    Teams
  } = _ref5;
  return Teams.loading;
};
const selectHasError = _ref6 => {
  let {
    Teams
  } = _ref6;
  return Teams.error;
};
setGlobal('svs.components.marketplaceData.selectors.teams', {
  selectTeam,
  getTeams,
  getCustomerTeamsResult,
  getRetailer,
  getRetailers,
  getRetailerTeamsResult,
  selectRetailerTeamsOffset,
  selectCustomerTeamsOffset,
  selectIsLoading,
  selectHasError
});

 })(window);