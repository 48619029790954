(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/draws.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/draws.js');
"use strict";

const _excluded = ["regCloseTime"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const {
  DRAWSTATE_OPEN,
  DRAWSTATE_DEFINED
} = svs.components.marketplaceData.constants;
const {
  productIds,
  getProdName
} = svs.components.lbUtils.sportProducts;
const selectDrawEventsErrorMessage = _ref => {
  let {
    Draws
  } = _ref;
  return Draws.drawEventsErrorMessage;
};
const getDraw = (state, _ref2) => {
  let {
    drawNumber,
    productName,
    productId
  } = _ref2;
  return (state.Draws.draws[productName] || []).find(d => d.drawNumber === parseInt(drawNumber, 10) && (
  productId ? d.productId === productId : true)) || {};
};
const getDrawEvents = (state, _ref3) => {
  let {
    drawNumber,
    productName
  } = _ref3;
  return (state.Draws.drawEvents[productName] || {})[drawNumber];
};
const selectOpenDrawsByProduct = function (state, productName) {
  let acceptedStates = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [DRAWSTATE_OPEN];
  const draws = state.Draws.draws[productName];
  const isRacing = getProdName(productIds.RACING) === productName;
  acceptedStates = isRacing ? [DRAWSTATE_OPEN, DRAWSTATE_DEFINED] : acceptedStates;
  if (!draws) {
    return undefined;
  }
  return draws.filter(_ref4 => {
    let {
      drawStateId
    } = _ref4;
    return acceptedStates.indexOf(drawStateId) > -1;
  }).map(_ref5 => {
    let {
        regCloseTime
      } = _ref5,
      draw = _objectWithoutProperties(_ref5, _excluded);
    return _objectSpread(_objectSpread({}, draw), {}, {
      regCloseTime: new Date(regCloseTime)
    });
  });
};
function getDrawsByNumber(state, productNames) {
  const allDraws = [].concat(...productNames.map(productName => state.Draws.draws[productName] || []));
  const drawsByNumber = allDraws.reduce((acc, draw) => {
    acc[draw.drawNumber] = draw;
    return acc;
  }, {});
  return drawsByNumber;
}
const getIsLoadingProductDraws = (state, _ref6) => {
  let {
    productName
  } = _ref6;
  return Boolean((state.Draws.drawLoading[productName] || {}).loading);
};
function getForecast(state, productId, drawNumber) {
  return state.Draws.forecast.find(forecast => forecast.productId === productId && forecast.drawNumber === drawNumber);
}
const selectSportkryss = (state, drawNumber) => {
  var _state$Draws;
  return (_state$Draws = state.Draws) === null || _state$Draws === void 0 || (_state$Draws = _state$Draws.draws) === null || _state$Draws === void 0 || (_state$Draws = _state$Draws.sportKryss) === null || _state$Draws === void 0 ? void 0 : _state$Draws.find(sportkryss => sportkryss.drawNumber === drawNumber);
};
setGlobal('svs.components.marketplaceData.selectors.draws', {
  getDraw,
  getDrawEvents,
  getIsLoadingProductDraws,
  selectOpenDrawsByProduct,
  getDrawsByNumber,
  getForecast,
  selectSportkryss,
  selectDrawEventsErrorMessage
});

 })(window);