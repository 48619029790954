(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/filters.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/filters.js');
"use strict";


const selectSearchFilters = state => state.BrowseGamesFilter || {};
const selectGameResults = state => state.BrowseGamesResults || {};
setGlobal('svs.components.marketplaceData.selectors.filters', {
  selectSearchFilters,
  selectGameResults
});

 })(window);