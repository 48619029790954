(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/funds.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/funds.js');
"use strict";


const selectFunds = state => state.Funds.data;
const selectJackpots = state => state.Jackpots.data;
const selectFundsError = state => state.Funds.error;
const selectJackpotsError = state => state.Jackpots.error;
const selectFundsIsLoading = state => state.Funds.isLoading;
const selectJackpotsIsLoading = state => state.Jackpots.isLoading;
const selectFundsLastFetch = state => state.Funds.lastFetch;
const selectJackpotsLastFetch = state => state.Jackpots.isLoading;
const selectJackpotByDrawNumberAndProductId = (state, _ref) => {
  let {
    drawNumber,
    productId
  } = _ref;
  const funds = selectJackpots(state);
  return funds.find(fund => fund.drawNumber === drawNumber && fund.productId === productId);
};
setGlobal('svs.components.marketplaceData.selectors.funds', {
  selectFunds,
  selectJackpots,
  selectFundsError,
  selectJackpotsError,
  selectFundsIsLoading,
  selectJackpotsIsLoading,
  selectFundsLastFetch,
  selectJackpotsLastFetch,
  selectJackpotByDrawNumberAndProductId
});

 })(window);