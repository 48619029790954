(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/games.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/games.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  helpers
} = svs.components.marketplaceData.selectors;
const getBrowseGamesGroup = (state, _ref) => {
  let {
    groupId
  } = _ref;
  return state.BrowseGamesResults.data.find(_ref2 => {
    let {
      id
    } = _ref2;
    return id === groupId;
  });
};
const getBrowseGames = (state, _ref3) => {
  let {
    groupId
  } = _ref3;
  return getBrowseGamesGroup(state, {
    groupId
  }).games;
};
const getIsBrowseGamesLoading = state => state.BrowseGamesResults.data.loading;
const getAmountBrowseGames = (state, _ref4) => {
  let {
    groupId
  } = _ref4;
  return getBrowseGamesGroup(state, {
    groupId
  }).gamesAmount;
};
const getBrowseGame = (state, _ref5) => {
  var _getBrowseGamesGroup;
  let {
    activatedDrawId,
    groupId
  } = _ref5;
  return state.BrowseGamesResults.data.find(g => g.activatedDrawId === parseInt(activatedDrawId, 10)) || (((_getBrowseGamesGroup = getBrowseGamesGroup(state, {
    groupId
  })) === null || _getBrowseGamesGroup === void 0 ? void 0 : _getBrowseGamesGroup.games) || []).find(g => g.activatedDrawId === parseInt(activatedDrawId, 10)) || {};
};

const selectGame = (state, _ref6) => {
  let {
    activatedDrawId,
    groupId
  } = _ref6;
  let game = ((state.Games[groupId] || {}).data || []).find(g => g.activatedDrawId === parseInt(activatedDrawId, 10));
  if (game) {
    game = _objectSpread(_objectSpread({}, game), {}, {
      playableAmount: helpers.getPlayableAmount(game)
    });
  }
  return game;
};

const getGame = (state, _ref7) => {
  let {
    activatedDrawId,
    groupId
  } = _ref7;
  return selectGame(state, {
    activatedDrawId,
    groupId
  });
};
const selectGames = (state, _ref8) => {
  let {
    groupId
  } = _ref8;
  return ((state.Games[groupId] || {}).data || []).filter(g => !g.isFinished) || [];
};
const getActiveGamesFetchedCount = (state, _ref9) => {
  let {
    groupId
  } = _ref9;
  return (state.Games[groupId] || {}).activeGamesFetched || 0;
};
const getActiveGamesFetchableCount = (state, _ref10) => {
  let {
    groupId
  } = _ref10;
  return (state.Games[groupId] || {}).activeGamesFetchable || 0;
};
const selectGamesHistory = (_ref11, _ref12) => {
  let {
    Games
  } = _ref11;
  let {
    groupId
  } = _ref12;
  return ((Games[groupId] || {}).data || []).filter(g => g.isFinished);
};
const getIsLoading = (state, _ref13) => {
  let {
    groupId,
    activatedDrawId
  } = _ref13;
  if (groupId) {
    if (!state.Games[groupId]) {
      return;
    }
    return state.Games[groupId].loading;
  }
  if (activatedDrawId) {
    if (!(state.LoadingSingleGames || {})[activatedDrawId]) {
      return;
    }
    return state.LoadingSingleGames[activatedDrawId].loading;
  }
};
const getIsError = (state, _ref14) => {
  let {
    groupId
  } = _ref14;
  if (!state.Games[groupId]) {
    return;
  }
  return Boolean(state.Games[groupId].error);
};
const getIsRemoving = (state, _ref15) => {
  let {
    activatedDrawId,
    groupId
  } = _ref15;
  const removing = (state.Games[groupId] || {}).removing || {};
  if (removing.activatedDrawId) {
    return removing.activatedDrawId === activatedDrawId;
  }
  return false;
};
const getIsRemoved = (state, _ref16) => {
  let {
    activatedDrawId,
    groupId
  } = _ref16;
  const removing = (state.Games[groupId] || {}).removing || {};
  return removing.activatedDrawId === parseInt(activatedDrawId, 10) && removing.status === 'success';
};
const getPurchasedFractionsForMember = (state, _ref17) => {
  let {
    activatedDrawId,
    memberId,
    groupId
  } = _ref17;
  return ((getGame(state, {
    activatedDrawId,
    groupId
  }) || {}).fractions || {})[memberId];
};
const getPurchasedFractionsForCurrentMember = (state, _ref18) => {
  let {
    activatedDrawId,
    groupId
  } = _ref18;
  if (state.Games) {
    return (getGame(state, {
      activatedDrawId,
      groupId
    }) || {}).currentUserNumberOfShares;
  }
  return 0;
};
const getGamesWithStatus = (_ref19, _ref20) => {
  let {
    Games
  } = _ref19;
  let {
    groupId,
    status
  } = _ref20;
  return ((Games[groupId] || {}).data || []).filter(game => {
    if (!status) {
      return true;
    }
    return status.indexOf(game.status) !== -1;
  });
};
function getGameByActivatedDrawId(state, groupId, activatedDrawId) {
  return ((state.Games[groupId] || {}).data || []).find(game => game.activatedDrawId === activatedDrawId);
}
function getGameByContainerId(state, groupId, containerId) {
  return ((state.Games[groupId] || {}).data || []).find(game => game.containerId === containerId);
}
function getGamesByContainerId(state, groupId, containerId) {
  return ((state.Games[groupId] || {}).data || []).filter(game => game.containerId === containerId);
}
function selectHasGameInMemory(state, _ref21) {
  let {
    activatedDrawId,
    groupId
  } = _ref21;
  return Boolean(((state.Games[groupId] || {}).data || []).find(g => g.activatedDrawId === parseInt(activatedDrawId, 10)));
}
const selectHasUserParticipatedInAnyGame = (state, _ref22) => {
  let {
    groupId,
    memberId
  } = _ref22;
  const games = (state.Games[groupId] || {}).data || [];
  return games.some(game => game.fractions[memberId]);
};
setGlobal('svs.components.marketplaceData.selectors.games', {
  getAmountBrowseGames,
  getBrowseGames,
  getBrowseGame,
  getIsBrowseGamesLoading,
  getGame,
  selectGame,
  selectGames,
  getActiveGamesFetchedCount,
  getActiveGamesFetchableCount,
  selectGamesHistory,
  getIsLoading,
  getIsError,
  getIsRemoving,
  getIsRemoved,
  getGamesWithStatus,
  getPurchasedFractionsForMember,
  getPurchasedFractionsForCurrentMember,
  getGameByActivatedDrawId,
  getGameByContainerId,
  getGamesByContainerId,
  selectHasGameInMemory,
  selectHasUserParticipatedInAnyGame
});

 })(window);