(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/members.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/members.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const getMembersForTeam = (state, _ref) => {
  let {
    groupId
  } = _ref;
  return state.Members[groupId];
};
const selectMe = (state, _ref2) => {
  let {
    groupId
  } = _ref2;
  return (getMembersForTeam(state, {
    groupId
  }) || []).find(m => m.isMe) || null;
};
const seletMembersInTeam = (state, _ref3) => {
  let {
    groupId
  } = _ref3;
  return getMembersForTeam(state, {
    groupId
  }) || [];
};

const selectWeightedMembers = (state, _ref4) => {
  let {
    groupId,
    activatedDrawId
  } = _ref4;
  const members = seletMembersInTeam(state, {
    groupId
  });
  if (state.Games[groupId]) {
    const games = state.Games[groupId].data || {};
    const game = games.find(game => game.activatedDrawId === Number(activatedDrawId));
    return members.map(member => _objectSpread(_objectSpread({}, member), {}, {
      fractions: game && game.fractions && game.fractions[member.id] || 0
    }));
  }
  return members;
};
setGlobal('svs.components.marketplaceData.selectors.members', {
  selectMe,
  getMembersForTeam,
  seletMembersInTeam,
  selectWeightedMembers
});

 })(window);