(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/racing.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/racing.js');
"use strict";

const {
  selectors
} = svs.components.marketplaceData;
const {
  getProdName,
  productIds
} = svs.components.lbUtils.sportProducts;
const selectRaceDraw = (state, drawNumber) => selectors.draws.getDraw(state, {
  drawNumber,
  productName: getProdName(productIds.RACING)
});
const selectRace = (state, drawNumber) => {
  const draw = selectRaceDraw(state, drawNumber) || {};
  return state.Races[draw.raceId];
};
const selectMeeting = (state, drawNumber) => {
  const race = selectRace(state, drawNumber) || {};
  const meeting = state.Meetings[race.meetingId];
  return meeting;
};
setGlobal('svs.components.marketplaceData.selectors.racing', {
  selectRace,
  selectMeeting,
  selectRaceDraw
});

 })(window);