(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/selectors/assets/javascripts/create-team.js') >= 0) return;  svs.modules.push('/components/marketplace-data/selectors/assets/javascripts/create-team.js');
"use strict";


function selectCreateTeam(state) {
  return state.CreateTeam || {};
}
setGlobal('svs.components.marketplaceData.selectors.createTeam', {
  selectCreateTeam
});

 })(window);